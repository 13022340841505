// app/javascript/channels/appearance_channel.js
import consumer from "./consumer"

consumer.subscriptions.create({ channel: "SmartPlugChannel"}, {
    connected() {console.log("connected:: to channel ") },

    disconnected() {},
    received(data) {},


});
