let slideIndex
let slides

export default function() {
    const slideshow = $('#slideshow')
    slides = slideshow.find('.slide')
    slideIndex = 0
    console.log(slides)
    setUpSlideshow(slideshow)
}

function setUpSlideshow(slideshow) {
    const nextButton = $(slideshow).find('[data-direction="next"]')
    const prevButton = $(slideshow).find('[data-direction="prev"]')
    nextButton.on('click', function() {
        changeSlide(1)
        $(this).blur()
    })
    prevButton.on('click', function() {
        changeSlide(-1)
        $(this).blur()
    })
}

function changeSlide(n) {
    const oldIndex = slideIndex
    if (slideIndex + n < slides.length && slideIndex + n >= 0) {
        slideIndex += n
        $(slides[slideIndex]).show()
        $(slides[oldIndex]).hide()
    }
}