const AXIS_COLOR = '#d6d6d6'

export default class AxesHelper {
    constructor(labels, xLabel, yLabel, yLabel2, beginAtZero, isRange=false, doubleYAxes=false, rangeAxis='', xAxesType='', stacked=false, extend=true) {
        this.labels = labels
        this.beginAtZero = beginAtZero
        this.isRange = isRange
        this.rangeAxis = rangeAxis
        this.doubleYAxis = doubleYAxes
        this.xLabel = xLabel
        this.yLabel = yLabel
        this.yLabel2 = yLabel2
        this.stacked = stacked
        this.xAxesType = xAxesType
        this.extend = extend
    }

    exportScales () {
        let xAxes = []
        let yAxes = []
        if (this.isRange) {
            if (this.rangeAxis === 'y') {
                xAxes = this.#_exportXAxes()
                yAxes = this.#_exportRangeYAxes()
            } else {
                xAxes = this.#_exportRangeXAxes()
                yAxes = this.#_exportYAxes()
            }
        } else {
            xAxes = this.#_exportXAxes()
            yAxes = this.#_exportYAxes()
        }

        return {
            xAxes, yAxes
        }
    }

    #_exportXAxes() {
        const xAxes = [
            {
                scaleLabel: this.#_getLabelObject(this.xLabel),
                stacked: this.stacked,
                ticks: {
                    beginAtZero: this.beginAtZero,
                    fontColor: 'black'
                },
                gridLines: {
                    color: AXIS_COLOR,
                    zeroLineColor: 'black'
                }
            }
        ]

        if (this.xAxesType === 'time') {
            xAxes[0]['type'] = 'time'
            xAxes[0]['time'] = {
                unit: 'minute',
                stepSize: 1
            }
        }

        return xAxes
    }

    #_exportYAxes() {
        if (this.doubleYAxis) {
            const obj = this

            let y_axis = [
                {
                    id: 'y_left',
                    position: 'left',
                    scaleLabel: this.#_getLabelObject(this.yLabel),
                    ticks: {
                        beginAtZero: this.beginAtZero,
                    },
                    gridLines: {
                        // color: "rgba(0, 0, 0, 0)",
                        zeroLineColor: 'black'
                    }
                },
                {
                    id: 'y_right',
                    position: 'right',
                    scaleLabel: this.#_getLabelObject(this.yLabel2),
                    ticks: {
                        beginAtZero: this.beginAtZero,
                        callback : function(value, index, values){
                            if (value < this.rightMin) this.rightMin = value

                            return value
                        },
                        fontColor: '#2b2b2b'
                    },
                    gridLines: {
                        // color: "rgba(0, 0, 0, 0)",
                        zeroLineColor: 'black'
                    }
                }
            ]
            return y_axis
        } else {
            return [
                {
                    scaleLabel: this.#_getLabelObject(this.yLabel),
                    stacked: this.stacked,
                    ticks: {
                        beginAtZero: this.beginAtZero,
                        fontColor: 'black'
                    },
                    gridLines: {
                      // color: AXIS_COLOR,
                      zeroLineColor: 'black'
                    }
                }
            ]
        }
    }

    #_exportRangeYAxes() {
        let extendedLabels = [...this.labels]
        extendedLabels.push(this.labels.length)

        return [
            {
                display: false,
                barPercentage: 1.0,
                ticks: {
                    // autoSkip: false,
                    max: this.labels.length - 1,
                }
            },
            {
                display: true,
                scaleLabel: this.#_getLabelObject(this.yLabel),
                ticks: {
                    beginAtZero: this.beginAtZero,
                    stepSize: 1.0 / this.labels.length,
                    // autoSkip: false,
                    callback: function (value, index, values) {
                        return extendedLabels[index];
                    },
                    fontColor: 'black'
                },
                gridLines: {
                  // color: AXIS_COLOR,
                  zeroLineColor: 'black'
                }
            },
        ]
    }

    #_exportRangeXAxes() {
        let extendedLabels = [...this.labels]
        let step = 2

        if(this.extend) {
            extendedLabels.push(this.labels.length)
            this.labels.push(this.labels.length)
        }

        return [
            {
                display: false,
                barPercentage: 1.0,
                stacked: this.stacked,
                ticks: {
                    max: this.labels.length - step
                }
            },
            {
                display: true,
                scaleLabel: this.#_getLabelObject(this.xLabel),
                ticks: {
                    callback: function (value, index, values) {
                        return extendedLabels[index];
                    },
                    fontColor: 'black'
                },
                gridLines: {
                  color: AXIS_COLOR,
                  zeroLineColor: 'black'
                }
            }
        ]
    }

    #_getLabelObject (str) {
        return {
            display: true,
            fontSize: 14,
            labelString: str,
            fontColor: 'black'
        }
    }
}
