import consumer from "channels/consumer";
import toastr from "toastr"

export default function() {
  const userBtn = $('#user-menu')
  const userId = $(userBtn).data('user_id')

  if(!isAlreadyOpen(userId))
    setUpSubscription(userId)
}

// checks if there is already an open channel
function isAlreadyOpen(userId) {
  if(!userId)
    return true

  const consumerSubscriptions = consumer.subscriptions.subscriptions
  const activeSubscriptions = consumerSubscriptions.map(e => JSON.parse(e.identifier))
  return activeSubscriptions.findIndex(e => e.id === userId) > -1
}

function setUpSubscription(userId) {
  consumer.subscriptions.create({ channel: "NotificationChannel", id: userId }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      console.log('what am i doing here:', data)
      toastr.options.onclick = function(){ console.log('Hey I was pressed'); }
      toastr.options.closeButton = true;
      toastr.options.closeHtml = '<button class="toast-close-button">x</button>';
      toastr.options.hideDuration = 100;
      toastr.info(data['body'] +
        '<br>' + data['time'] +
       '<br><a href="' + data['url'] + '" style="text-decoration: underline;">Open</a>'
        );

      // update notification bell
      var numberField = $('#notifications_number');
      var notificationsNumber = parseInt($(numberField).text());
      $(numberField).text(notificationsNumber + 1);

      // if in notifications page then add the new notification on the fly
      var url = window.location.href;
      var host = window.location.host;
      if(url.indexOf('http://' + host + '/notifications') != -1) {
        var table = $(".hovertable tbody")[0];
        var tr = document.createElement('tr');

        var senderTd = document.createElement('td');
        senderTd.textContent = data['sender'];
        tr.appendChild(senderTd);

        var titleTd = document.createElement('td');
        titleTd.textContent = data['title'];
        tr.appendChild(titleTd);

        var bodyTd = document.createElement('td');
        bodyTd.textContent = data['body'];
        tr.appendChild(bodyTd);

        var timeTd = document.createElement('td');
        timeTd.textContent = data['time'];
        tr.appendChild(timeTd);

        var urlTd = document.createElement('td');
        var link = document.createElement('a');
        link.href = data['url'] + '?notification_id=' + data['notification_id'];
        link.textContent = 'Open item';
        var newText = document.createElement('i');
        newText.textContent = ' (New)';
        urlTd.appendChild(link);
        urlTd.appendChild(newText);
        tr.appendChild(urlTd);

        table.prepend(tr);
      }

      // send global event in case some function needs to act after a notification is received
      $(document).trigger('notification_received', [data.type, data.details])
    }
  });
}
