// This file is used to handle the selectors and selectors events
// above the chart when other_timeseries is defined
// TODO: Find another way to call the update funciton from the GenericChartHelper
//  because now all the methods are exposed in here
export default class DatasetSelector {
  constructor(chartObj, genericChartHelper) {
    this.genericChartHelper = genericChartHelper
    this.chartObj = chartObj

    this.selector_1 = null
    this.selector_2 = null

    this.value_1 = null
    this.value_2 = null

    this.otherTimeseries = chartObj.data('other_timeseries')

    if(this.otherTimeseries) {

      const container = $(chartObj).parents('.row.generic-chart')

      if(Array.isArray(this.otherTimeseries)) {
        this.#initializeSingle(container)
      } else if(this.otherTimeseries.constructor === Object) {
        this.#initializeDouble(container)

      }
    }
  }

  #initializeSingle(container) {
    const obj = this
    this.selector_1 = $(container).find('select[id^="selected-series"]')
    $(this.selector_1).on('change', function(event, value) {
      obj.#updateValue(1)
      obj.#updateDatasetSingle(value)
    })
  }

  #updateDatasetSingle(value) {
    console.log(this.value_1)
    this.genericChartHelper.changeTimeSeries(this.value_1)
    if(value !== 'auto')
        $(this.chartObj).trigger('input_changed', [this.value_1])
  }

  #initializeDouble(container) {
    const obj = this
    this.selector_1 = $(container).find('select[id^="selected-key"]')
    this.selector_2 = $(container).find('select[id^="selected-series"]')

    this.#updateValue(1)
    this.#updateValue(2)

    $(this.selector_1).on('change', function() {
      obj.#updateValue(1)
      obj.#adjustSelectorOptions()
      obj.#updateDatasetDouble()
    })

    $(this.selector_2).on('change', function() {
      obj.#updateValue(2)
      obj.#updateDatasetDouble()
    })
  }

  #adjustSelectorOptions() {
    const datasets = this.otherTimeseries[this.value_1.toUpperCase()]
    const options = datasets.map(d => {
      if(d.select_label !== undefined) return d.select_label
      else return d.datasets[0].label.toLowerCase()
    })
    const currentSelected = $(this.selector_2).find('option:selected')[0].value
    $(this.selector_2).find('option').remove()
    for(let i = 0; i < options.length; i++) {
      let optionElement = document.createElement('option')
      optionElement.value = i
      optionElement.text = options[i]
      $(this.selector_2).append(optionElement)
    }
    if(options.includes(currentSelected)) {
      $(this.selector_2).val(currentSelected)
    }
    this.#updateValue(2)
  }

  #updateDatasetDouble() {
    this.genericChartHelper.changeTimeSeriesDouble(this.value_1, this.value_2)
    $(this.chartObj).trigger('input_changed', [this.value_2])
  }

  #updateValue(which) {
    if(which === 1)
      this.value_1 = $(this.selector_1).find('option:selected').val()
    else if(which === 2)
      this.value_2 = $(this.selector_2).find('option:selected').val()
  }
}
